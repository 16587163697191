@import '../variables.scss';

.score-container {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5rem;
  margin-right: 3rem;

  .score-table {
    background: none;
    color: $text;
    td {
      text-align: center;
    }
  }

  @include desktop {
    margin-right: 5rem;
  }

  @include mobile {
    top: 1.3rem;
    left: 0;
    width: 6rem;
    margin-top: 0;
    margin-right: 0;
    margin: auto;

    .score-table {
      font-size: 0.8rem;
      td {
        padding: 0.5rem 0.5rem;
      }
    }
  }

}
