@import '../variables';

.card-wrapper {
  position: relative;
  width: 0;
  height: 0;
}

img.playing-card {
  position: absolute;
  width: $card_width;
  height: $card_height;
  max-width: none;
	margin: -$card_width/2;
	padding: 0;
	border: 0;
	vertical-align: initial;
  box-sizing: initial;
  transition: ease 0.1s transform;
  &.is-selectable {
    cursor: pointer;
    &:hover {
      z-index: 1;
      .hand & {
        transform: translateY(-$card_space);
      }
      .table-slot & {
        transform: translateY($card_space/2);
      }
    }
  }
}