@import '../variables.scss';

.commands {
  @include until($tablet) {
    .section {
      padding: 1rem 0;
    }
  }
}

.wrapper {
  max-width: 100%;
  @include mobile {
    width: 100%;
  }
}

.mode-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  .field {
    margin: 0;
  }
  .tip {
    margin-left: 1rem;
  }
}

.waiting {
  .title {
    @include desktop {
      margin-bottom: 4rem;
    }
  }
}

ul.players-waiting-list {
  position: relative;
  max-width: 100%;
  margin-top: 1rem;
  // margin-bottom: 1rem;
  min-height: 10rem;
  @include tablet {
    margin-top: 3rem;
    width: 30rem;
    min-height: 15rem;
    // margin-bottom: 3rem;
  }
  li {
    // margin-top: 1rem;
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    // &:first-child {
    //   margin-bottom: 4rem;
    //   @include desktop {
    //     margin-bottom: 8rem;
    //   }
    // }
    &:nth-child(2) {
      left: 0;
    }
    &:nth-child(3) {
      left: 0;
      right: 0;
    }
    &:nth-child(4) {
      right: 0;
    }
    &:nth-child(5) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

ul.actions {
  li {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    p.explain {
      margin-top: 0.5rem;
    }
  }
}