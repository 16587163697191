@import '../variables';

.overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.7);
  overflow-x: hidden;
  transition: 0.5s;
  cursor: pointer;
}

.is-visible {
  display: block;
}

.overlay-content {
  height: 2*$card-height;
  width: 2*$card-width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .card-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    &:first-child {
      bottom: -120%;
    }
    &:nth-child(2) {
      left: -120%;
    }
    &:nth-child(3) {
      top: -120%;
    }
    &:nth-child(4) {
      right: -120%;
    }
  }
}