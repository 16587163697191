@import '../variables';

.playing-table {
  @include table-space();
  // position: relative;
  // height: 2 * ($card_height + $card_space);
  // width: 100%;
  // margin-bottom: 4rem;

  button.is-collect {
    position: absolute;
    bottom: -3rem;
    right: 0;
    left: 0;
    margin: auto;
  }

  .table-slot {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: $card_width;
    height: $card_height;

    .card-wrapper {
      width: auto;
      height: auto;
      img.playing-card {
        margin: 0;
      }
     }

    &.is-north {
      top: -50%;
    }
    &.is-east {
      right: -50%;
    }
    &.is-south {
      bottom: -50%;
    }
    &.is-west {
      left: -50%;
    }
  }
}