@import '../variables';

.player {
  &.is-north, &.is-south {
    width: 100%;
    text-align: center;
  }

  &.is-west, &.is-east {
    margin: 1rem 0;
    min-width: 10vw;

  }

  &.is-north {
    .name {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }

  @include compact-mode() {
    $name-width: 50vh;
    &:not(.is-south) {
      .hand {
        display: none;
      }
    }
    &.is-west, &.is-east {
      position: relative;
      margin: 0;
      width: 0;
      height: 0;
      min-width: 0;
      .name {
        position: absolute;
        width: $name-width;
        left: -$name-width/2;
        right: -$name-width/2;
        margin: auto;
        transform-origin: center;
      }
    }

    &.is-west {
      .name {
        transform: rotate(-90deg);
      }
    }
    &.is-east {
      position: relative;
      .name {
        transform: rotate(90deg);
      }
    }
  }

  .name {
    text-align: center;
    margin-bottom: 1rem;
    .icon {
      margin-right: 0.5rem;
      vertical-align: bottom;
      img {
        vertical-align: middle;
      }
    }

    span.clickable {
      cursor: pointer;
    }

    &.is-dealer {
      font-weight: bold;
    }
  }
}