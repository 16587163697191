@import '../variables';

.hand {
	padding: $card_width / 2;
	margin: auto;
  display: flex;
  width: 100%;
  max-width: 9*$card_width;
  height: $card_height;
  flex-flow: row nowrap;
  justify-content: space-around;
  &.is-compact {
    max-width: 3*$card_width;
    margin: auto;
  }
  &.with-tricks {
    margin-bottom: 3rem;
  }

  .trick {
    display: flex;
    width: 1.5*$card_width;
    justify-content: space-around;
    margin-bottom: 3rem;
    &:nth-child(even) {
      position: relative;
      top: 2rem;
    }
  }
}